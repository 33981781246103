import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Image } from "semantic-ui-react";
import promotion01 from '../../assets/images/proteccion.jpg';
import promotion02 from "../../assets/images/donacion.jpg";
import promotion03 from "../../assets/images/globulos.jpg";


const carouselCaption = {
  'textAlign': 'left'
}

const PromotionCarousel = () => {
        return (
            <Carousel>
            <Carousel.Item >
              <Image height="fit-content" alt="Banner 1" src={promotion01} />
              <Carousel.Caption style={carouselCaption}>
                <h3>Mi Laboratorio</h3>
                <h4>
                  Un nuevo e innovador servicio para hacer su vida más fácil.
                </h4>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item >
              <Image height="fit-content" alt="Banner 2" src={promotion02} />
              <Carousel.Caption style={carouselCaption}>
                <h3>Usuario y contraseña.</h3>
                <h4>
                  Su privacidad y seguridad están en el primer nivel de
                  nuestras prioridades. Ahora todo el historial de sus
                  resultados e información personal permanecen más seguros.
                </h4>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item >
              <Image height="fit-content" alt="Banner 3" src={promotion03} />
              <Carousel.Caption style={carouselCaption}>
                <h3>Su sangre es nueva vida! </h3>
                <h4>
                  Acompáñenos en las campañas de donación. Nuestro
                  experimentado equipo y la unidad móvil de Banco de Sangre
                  estarán listos para hacer su donación exitosa!
                </h4>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        );
    }

export default PromotionCarousel;
