import api from "../api";
import { SIGNED_AGREEMENTS } from "../types";

export const fetchUserAgreements = () => api.user.fetchUserAgreements();
export const updateUserAgreements = ({
  agreement_id,
  accepted,
  agreement_name
}) => api.user.updateUserAgreements({ agreement_id, accepted, agreement_name });
export const contactUs = data => api.user.contactUs(data);


