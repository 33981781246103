// Constants
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const FETCH_ORDER = "FETCH_ORDER";
export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_HISTORIC = "FETCH_HISTORIC";
export const ADD_TO_LIST = "ADD_TO_LIST";
export const REMOVE_FROM_LIST = "REMOVE_FROM_LIST";
export const RESET_LIST = "RESET_LIST";
export const REMOVE_ALL = "REMOVE_ALL";
export const SHOW_ORDER_LIST = "SHOW_ORDER_LIST";
export const SHOW_ORDER_FAQS = "SHOW_ORDER_FAQS";

export const SIGNED_AGREEMENTS = "SIGNED_AGREEMENTS";
