import React from 'react';
import { Icon } from 'semantic-ui-react';

const FloatingButton = ({ showFloat, visible }) => {
  return (
    <button className="floatButton" onClick={showFloat}>
      {visible ? (
        <Icon name="x" className="floatButtonIcon" />
      ) : (
        <Icon name="mail" className="floatButtonIcon" />
      )}
    </button>
  );
};

export default FloatingButton;
