import React from "react";
import Validator from "validator";
import isEmpty from "lodash/isEmpty";
import ReactCSSTransitionGroup from "react-addons-css-transition-group"; // ES6
import { contactUs } from "../../actions/userActions";

function validateInput(data) {
  let errors = {};

  if (Validator.isEmpty(data.username)) {
    errors.username = "Este campo es requerido.";
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = "Este campo es requerido.";
  }

  if (!Validator.isEmail(data.email)) {
    errors.email = "Correo invalido";
  }

  if (Validator.isEmpty(data.subject)) {
    errors.subject = "Este campo es requerido.";
  }

  if (Validator.isEmpty(data.message)) {
    errors.message = "Este campo es requerido.";
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
}

const InlineError = ({ text }) => {
  return <span style={{ color: "red" }}>{text}</span>;
};

class FloatingForm extends React.Component {
  state = {
    data: {
      username: "",
      email: "",
      subject: "",
      message: ""
    },
    response: "",
    contactUsResponse: false,
    errors: {}
  };

  submit = data => {
    contactUs(data)
      .then(res => {
        this.setState({
          contactUsResponse: true,
          response: res.data.message
        });
      })
      .catch(() => {
        this.setState({
          contactUsResponse: true,
          response: "No se pudo enviar el mensaje"
        });
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { errors, isValid } = validateInput(this.state.data);
    if (isValid) {
      this.submit(this.state.data);
      this.setState({
        data: {
          username: "",
          email: "",
          subject: "",
          message: ""
        },
        response: "",
        errors: {}
      });
    }
    this.setState({ errors });

    // this.props.showFloat(e);
  };

  handleOnChange = e => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value }
    });
  };

  closeButton = e => {
    e.preventDefault();
    this.setState({
      contactUsResponse: false
    });
    this.props.showFloat(e);
  };

  render() {
    const { visible } = this.props;
    const { data, response, errors, contactUsResponse } = this.state;

    return (
      <ReactCSSTransitionGroup
        transitionName="floatDiv"
        transitionEnterTimeout={400}
        transitionLeaveTimeout={400}
      >
        {visible ? (
          <div className="floatDiv">
            <h4 className="floatFormTitle" htmlFor="title">
              Contactenos! Envíenos sus sugerencias e inquietudes.
            </h4>{" "}
            {contactUsResponse ? (
              <div className="floatForm " htmlFor="response">
                <div className="responseMessage">{response}</div>
                <button
                  className="floatFormSubmitButton"
                  onClick={this.closeButton}
                >
                  Cerrar
                </button>
              </div>
            ) : (
              <form className="floatForm" onSubmit={this.handleSubmit}>
                <label htmlFor="username">
                  Nombre:
                  {errors.username ? (
                    <InlineError text={errors.username} />
                  ) : null}
                </label>
                <input
                  className="floatFormInput"
                  type="text"
                  name="username"
                  value={data.username}
                  onChange={this.handleOnChange}
                  autoComplete="off"
                />
                <label htmlFor="email">
                  Correo:{" "}
                  {errors.email ? <InlineError text={errors.email} /> : null}
                </label>
                <input
                  className="floatFormInput"
                  type="email"
                  name="email"
                  value={data.email}
                  onChange={this.handleOnChange}
                  autoComplete="off"
                />
                <label htmlFor="subject">
                  Asunto:{" "}
                  {errors.subject ? (
                    <InlineError text={errors.subject} />
                  ) : null}
                </label>
                <input
                  className="floatFormInput"
                  type="text"
                  name="subject"
                  value={data.subject}
                  onChange={this.handleOnChange}
                  autoComplete="off"
                />
                <label htmlFor="message">
                  Mensaje:{" "}
                  {errors.message ? (
                    <InlineError text={errors.message} />
                  ) : null}
                </label>
                <textarea
                  className="floatFormTextArea"
                  type="text"
                  name="message"
                  value={data.message}
                  onChange={this.handleOnChange}
                />
                <input
                  className="floatFormSubmitButton"
                  type="submit"
                  value="Enviar"
                />
              </form>
            )}
          </div>
        ) : null}
      </ReactCSSTransitionGroup>
    );
  }
}

export default FloatingForm;
