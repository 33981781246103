import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";
import { Image, Grid } from "semantic-ui-react";

import UserRoute from "./components/routes/UserRoute";
import GuestRoute from "./components/routes/GuestRoute";
import asyncComponent from "./components/AsyncComponent";
import PromotionCarousel from "./components/carousel/PromotionCarousel";
import FloatingButton from "./components/common/FloatingButton";
import FloatingForm from "./components/common/FloatingForm";

import "./App.css";
import "./style.css";
// import logo from "./assets/images/logo_auna.svg";
import logo from "./assets/images/laboratorio.png";
// import fb from "./assets/images/fb.png";
// import tw from "./assets/images/tw.png";
// import yt from "./assets/images/yt.png";
// import lk from "./assets/images/lk.png";
// import ing from "./assets/images/ing.png";
// Doing some async import...
const LoginPage = asyncComponent(() => import("./components/pages/LoginPage"));
const SignupPage = asyncComponent(() =>
  import("./components/pages/SignupPage")
);
const ForgotPasswordPage = asyncComponent(() =>
  import("./components/pages/ForgotPasswordPage")
);
const PreSignupPage = asyncComponent(() =>
  import("./components/pages/PreSignupPage")
);
const ResetPasswordPage = asyncComponent(() =>
  import("./components/pages/ResetPasswordPage")
);
const DashboardPage = asyncComponent(() =>
  import("./components/pages/DashboardPage")
);
const GuidePage = asyncComponent(() => import("./components/pages/GuidePage"));
// const FaqsPage = asyncComponent(() => import("./components/pages/FaqsPage"));

const AgreementsPage = asyncComponent(() =>
  import("./components/pages/AgreementsPage")
);

class App extends React.Component {
  state = {
    visible: false,
  };

  componentWillUpdate({ location, history }) {
    const gtag = window.gtag;
    if (location.pathname === this.props.location.pathname) {
      return;
    }
    if (history.action === "PUSH" && typeof gtag === "function") {
      gtag("config", "UA-117082806-3", {
        page_location: window.location.href,
        page_path: location.pathname,
      });
    }
  }

  showFloat = (e) => {
    e.preventDefault();
    this.setState({
      visible: !this.state.visible,
    });
  };

  render() {
    const { location } = this.props;
    const { visible } = this.state;
    return (
      <Grid style={{ marginLeft: "0rem", marginRight: "0rem" }}>
        <Grid.Row
          verticalAlign="middle"
          centered
          style={{ paddingBottom: "0px" }}
          className="Banner"
        >
          <Grid.Column mobile={12} tablet={4} computer={3}>
            <a href="https://resultados.lablasamericas.com.co">
              <Image id="svgImg" className="mobileLogo" src={logo} />
            </a>
          </Grid.Column>
          <Grid.Column
            id="Carousel"
            // only="tablet computer"
            tablet={12}
            computer={13}
            style={{ paddingLeft: "0em", paddingRight: "0em" }}
            textAlign
          >
            <PromotionCarousel />

            {/* <List horizontal>
              <List.Item>
                <Image
                  style={{
                    color: "#9f9f9f",

                    // fontFamily: "Poppins-Light",
                    fontSize: "13px",
                    fontWeight: "300",
                    // lineHeight: "13px",
                    margin: "5px 10px 0 0",
                  }}
                >
                  SÍGUENOS EN
                </Image>
              </List.Item>
              <List.Item>
                <Image
                  href="https://www.facebook.com/lasamericasauna/"
                  as="a"
                  src={fb}
                  target="_blank"
                ></Image>
              </List.Item>
              <List.Item>
                <Image
                  href="https://twitter.com/lasamericasauna"
                  as="a"
                  src={tw}
                  target="_blank"
                ></Image>
              </List.Item>
              <List.Item>
                <Image
                  href="http://www.youtube.com/user/canallasamericastv"
                  as="a"
                  src={yt}
                  target="_blank"
                ></Image>
              </List.Item>
              <List.Item>
                <Image
                  href="https://www.linkedin.com/in/promotora-medica-las-americas/"
                  as="a"
                  src={lk}
                  target="_blank"
                ></Image>
              </List.Item>
              <List.Item>
                <Image
                  href="https://www.instagram.com/lasamericasauna/"
                  as="a"
                  src={ing}
                  target="_blank"
                ></Image>
              </List.Item>
            </List> */}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Switch>
              <GuestRoute
                location={location}
                path="/signup"
                exact
                component={SignupPage}
              />
              <GuestRoute
                location={location}
                path="/forgot_password"
                exact
                component={ForgotPasswordPage}
              />
              <GuestRoute
                location={location}
                path="/register"
                exact
                component={PreSignupPage}
              />
              <GuestRoute
                location={location}
                path="/reset_password/:token"
                exact
                component={ResetPasswordPage}
              />
              <UserRoute
                location={location}
                path="/update_agreements"
                exact
                component={AgreementsPage}
              />
              <GuestRoute
                location={location}
                path="/guide"
                exact
                component={GuidePage}
              />

              <UserRoute
                location={location}
                path="/"
                exact
                component={DashboardPage}
              />

              <GuestRoute location={location} component={LoginPage} />
            </Switch>

            <FloatingForm visible={visible} showFloat={this.showFloat} />
            <FloatingButton visible={visible} showFloat={this.showFloat} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default App;
