import { USER_LOGGED_IN , SIGNED_AGREEMENTS} from "../types";

export default function user(state = {}, action = {}) {
	switch (action.type) {
		case USER_LOGGED_IN:
			return action.user;
		case SIGNED_AGREEMENTS:
				return action.user;
		default:
			return state;
	}
}
