import { SHOW_ORDER_FAQS } from "../types";

const INITIAL_SATE = { showFaqs: false };

export default function showFaqs(state = INITIAL_SATE, action) {
  switch (action.type) {
    case SHOW_ORDER_FAQS:
      return { ...state, showFaqs: !state.showFaqs };
    default:
      return state;
  }
}
